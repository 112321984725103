import React from 'react';
import '../css/iris.css';
import imageoverlap from './../images/bluewave.png';
import softnerl from './../images/website/products/softner-l.png';
import softners from './../images/website/products/softner-s.png';
import softenexl from './../images/website/products/softner-xl.png';

const SoftnersSection = () => {
  return (
    <div className='outer-container'>
      {/* Left Side */}
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h2>Softners</h2>
        <h1>IRIS</h1>
      </div>

      {/* Right Side */}
      <div style={{ flex: 1, position: 'relative' }}>
        <img 
          src={softners} 
          alt="Softner Small" 
          className="softner-image softner-small" 
        />
        <img 
          src={softnerl} 
          alt="Softner Medium" 
          className="softner-image softner-medium" 
        />
        <img 
          src={softenexl} 
          alt="Softner Large" 
          className="softner-image softner-large" 
        />
      </div>

      {/* Overlap Image */}
      <img 
        src={imageoverlap} 
        alt="Background Overlap" 
        className="overlap-image" 
      />
      <h2 className="filter-heading">FILTERS</h2>
    </div>
  );
};

export default SoftnersSection;



















// import React from 'react';
// import softners from './../images/website/products/softner-s.png';
// import softnerl from './../images/website/products/softner-l.png';
// import softenexl from './../images/website/products/softner-xl.png';
// import imageoverlap from './../images/bluewave.png';

// const SoftnersSection = () => {
//   return (<>
//     <div style={{ display: 'flex', backgroundColor: 'black', height: '99vh', position: 'relative' }}>
           
//       {/* Left Side */}
//       <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//       <h2 style={{
//     fontFamily: 'Amiko',
//     fontSize: '100px',
//     color: 'white',
// }}>
//     Softners
// </h2>
// <h1 style={{
//     fontFamily: 'Amiko',
//     fontSize: '150px',
//     textAlign: 'center',
//     color: 'white',
//     marginBottom: '400px',
// }}>
//     IRIS
// </h1>

//       </div>

//       {/* Right Side */}
//       <div style={{ flex: 1, position: 'relative' }}>
//         <img 
//           src={softners} 
//           alt="Softner Small" 
//           style={{
//             width: '30%',
//             position: 'absolute',
//             bottom: '0%',
//             left: '40%',
//             // boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.25)',
//             zIndex: 2, 
//           }} 
//         />
//         <img 
//           src={softnerl} 
//           alt="Softner Medium" 
//           style={{
//             width: '27%', 
//             position: 'absolute',
//             bottom: '0%', 
//             left: '60%',
//             // boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.25)',
//             zIndex: 1, 
//           }} 
//         />
//         <img 
//           src={softenexl} 
//           alt="Softner Large" 
//           style={{
//             width: '30%', 
//             position: 'absolute',
//             bottom: '0', 
//             left: '85%',
//             transform: 'translateX(-50%)',
//             boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.25)',
//           }} 
//         />
//       </div>

//       {/* Overlap Image */}
//       <img 
//         src={imageoverlap} 
//         alt="Background Overlap" 
//         style={{
//           position: 'absolute',
//           top: '50%', // Adjust as needed
//           left: 0,
//           width: '100%', // Full width
//           height: '60%', // Full height to cover the section
//           objectFit: 'cover', // Cover the area
//           zIndex: 3, // Place it below other content
//         }} 
//       />
//       <h2 style={{
//             fontFamily: 'Amiko',
//             fontSize: '50px',
//             color: 'white',
//             marginTop: '650px',
//             zIndex: 5, // Bring this image to the front
//             position: 'absolute', // Add position to make it overlap correctly
//             textAlign:'center',
//             left:"45%",
//             textDecoration: 'underline'

//           }}>
//             FILTERS
//           </h2>
//     </div>
//     </>
//   );
// };

// export default SoftnersSection;

