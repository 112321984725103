import React from 'react';
import '../css/footer.css';


const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-row">
          <div className="footer-section">
            <h3>Water Purifiers</h3>
            <ul>
              <li><a href="#ro">RO Water Purifiers</a></li>
              <li><a href="#uv">UV Water Purifiers</a></li>
              <li><a href="#active-copper">Active Copper</a></li>
              <li><a href="#alkaline-boost">Alkaline Boost</a></li>
              <li><a href="#hot-ambient">Hot & Ambient</a></li>
              <li><a href="#stainless-steel">Stainless Steel Purifier</a></li>
              <li><a href="#slim">Slim Water Purifier</a></li>
              <li><a href="#under-counter">Under Counter Purifier</a></li>
              <li><a href="#spares">Spares & Accessories</a></li>
              <li><a href="#genuine-filters">Genuine Filters</a></li>
            </ul>
          </div>
  
          <div className="footer-section">
            <h3>Water Solutions</h3>
            <ul>
              <li><a href="#water-softeners">Water Softeners</a></li>
            </ul>
            <h3>Servicing</h3>
            <ul>
              <li><a href="#buy-amc">Buy AMC</a></li>
              <li>
            <h3>Air Purifiers</h3>
            <ul>
              <li><a href="#all-air-purifiers">All Air Purifiers</a></li>
              <li><a href="#filters">Filters</a></li>
            </ul>
         <a href="#raise-complaint">Raise A Complaint</a></li>
            </ul>
          </div>
  
          <div className="footer-section">
            <h3>Account</h3>
            <ul>
              <li><a href="#profile">Profile</a></li>
              <li><a href="#your-devices">Your Devices</a></li>
              <li><a href="#your-orders">Your Orders</a></li>
            </ul>
          </div>
  
          <div className="footer-section">
            <h3>About Euro Aqua Forbes</h3>
            <ul>
              <li><a href="#connect">Connect With Us</a></li>
              <li><a href="#investor-relations">Investor Relations</a></li>
              <li><a href="#press-release">Press Release</a></li>
              <li><a href="#service-center">Authorized Service Center</a></li>
              <li><a href="#office-near-you">Office Near You</a></li>
              <li><a href="#blog">Blog</a></li>
              <li><a href="#book-demo">Book a Demo</a></li>
            </ul>
          </div>
        </div>
  
        {/* <div className="footer-row1">
          <div className="footer-section">
            <h3>Vacuum Cleaners</h3>
            <ul>
              <li><a href="#robotic">Robotic</a></li>
              <li><a href="#upright">Upright</a></li>
              <li><a href="#canister">Canister</a></li>
              <li><a href="#wet-dry">Wet & Dry</a></li>
              <li><a href="#handheld">Handheld</a></li>
              <li><a href="#pet-grooming">Pet Grooming</a></li>
              <li><a href="#accessories">Accessories</a></li>
            </ul>
          </div>
  
          <div className="footer-section">
            <h3>Air Purifiers</h3>
            <ul>
              <li><a href="#all-air-purifiers">All Air Purifiers</a></li>
              <li><a href="#filters">Filters</a></li>
            </ul>
          </div>
        </div> */}
  
        <hr />
        <div className="footer-row">

        <div className="footer-section">
          <h3>Registered Office and Corporate Office</h3>
          <p>              Corporate Office 2511, Iconic Lower, 25th Floor, Sector 62, Noida - 201309
          </p>
          
        </div>
        <div className="footer-section">

        <h3>Manufacturer/Importer details</h3>
          <p>Surya Water & Enviro Engineers Pvt. Ltd.</p>
          
          </div>
          <div className="footer-section">

          <h3>Corporate Identity Number (CIN)</h3>
          <p> **************************</p>
</div>
        </div>

<div className="footer-bottom">
  <div className="footer-left">
    <p>© 2024 Surya Water & Enviro Engineers Pvt. Ltd. All Rights Reserved.</p>
  </div>

  <div className="footer-center">
    <a href="#privacy">Privacy</a> | 
    <a href="#terms">Terms of Use</a> | 
    <a href="#legal">Legal</a> | 
    <a href="#sitemap">Sitemap</a>
  </div>

  <div className="footer-right">
    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-facebook"></i>
    </a>
    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-instagram"></i>
    </a>
    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-twitter"></i>
    </a>
    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-linkedin"></i>
    </a>
  </div>
</div>
      </footer>
    );
  };
  
  export default Footer;
  
