import React, { useEffect, useState } from 'react';
import '../css/rosystem.css';
import softnerAura from './../images/website/products/aura.png'; // AURA
import softnerPearl from './../images/website/products/pearl.png'; // PEARL
import softnerSapphire from './../images/website/products/sapphire.png'; // SAPPHIRE

const RoSystem = () => {
  const images = [
    { src: softnerAura, name: 'AURA' },
    { src: softnerPearl, name: 'PEARL' },
    { src: softnerSapphire, name: 'SAPPHIRE' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Image rotation every 3 seconds
    return () => clearInterval(interval); // Clear interval on component unmount
  }, [images.length]);

  const getPrevIndex = () => (currentIndex - 1 + images.length) % images.length;
  const getNextIndex = () => (currentIndex + 1) % images.length;

  return (
    <div className="ro-system-container">
      <h2 className="title">RO SYSTEM</h2>
      <div className="slider-container">
        {/* Left Image */}
        <div className="image-container">
          <div className="small-image1 left">
            <img src={images[getPrevIndex()].src} alt={images[getPrevIndex()].name} />
          </div>
          <p>{images[getPrevIndex()].name}</p>
        </div>

        {/* Center Large Image */}
        <div className="image-container">
          <div className="large-image mid">
            <img src={images[currentIndex].src} alt={images[currentIndex].name} />
          </div>
          <p>{images[currentIndex].name}</p>
        </div>

        {/* Right Image */}
        <div className="image-container">
          <div className="small-image1 right">
            <img src={images[getNextIndex()].src} alt={images[getNextIndex()].name} />
          </div>
          <p>{images[getNextIndex()].name}</p>
        </div>
      </div>
      <hr style={{ margin: '30px auto', border: '2px solid #000', width: '95%' }} />

    </div>
  );
};

export default RoSystem;











// import React from 'react';
// import '../css/product2.css';
// import Image from './../images/MBB-removebg-preview.png';
// import Image1 from './../images/MWB-removebg-preview.png';

// const Product3 = () => {
//   return (
//     <>
//     <div className="aquaguard-section">
//       <div className="left-side">
//       <h2>EURO AQUA IRIS SOFTNER</h2>
//       <span>Hot Water Instantly</span>
//         <h1>Get 80ºC Steaming </h1>
//         <p>Starting at ₹19,999*</p>
//         <p className="additional-discount">Additional bank discounts applicable*</p>
//         <button className="shop-now">Shop Now</button>
//       </div>
     
//       <div className="right-side">
//   <img src={Image} alt="Aquaguard Blaze Insta" style={{ width: '30%', height: 'auto' }} />
// </div>
//     </div>
//     <div className="feature-cards">
//     <div className="card">
//       <img src={Image1} alt="Instant Hot Water"  style={{ width: '30%', height: '70%' }} />
//       <hr style={{ margin: '0px auto', border: '2px solid #ccc', width: '95%' }} />
//       <h3>Instant Hot Water</h3>
//       <p>Get steaming hot water at 80ºC instantly</p>
//     </div>
//     <div className="card">
//       <img src={Image1} alt="Dual Steel Tank" style={{ width: '30%', height: '70%' }} />
//       <hr style={{ margin: '0px auto', border: '2px solid #ccc', width: '95%' }} />
//       <h3>Dual Steel Tank</h3>
//       <p>Stainless steel tank keeps water fresher for longer</p>
//     </div>
//     <div className="card">
//       <img src={Image1} alt="Child Lock" style={{ width: '30%', height: '70%' }} />
//       <hr style={{ margin: '0px auto', border: '2px solid #ccc', width: '95%' }} />
//       <h3>Child Lock</h3>
//       <p>Prevents chances of accidental injuries</p>
//     </div>
//   </div>
//   <hr style={{ margin: '50px auto', border: '2px solid #ccc', width: '95%' }} />

//   </>
//   );
// };

// export default Product3;
