import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import WaterPurifiers from './components/purifiers';
import WhyBuy from './components/whybuy';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import Router components
import Home from './index/home';
import IRIS from './components/softner';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aquaguard" element={<IRIS />} />
        <Route path="/whybuy" element={<WhyBuy />} />
        <Route path="/water-purifiers" element={<WaterPurifiers />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;