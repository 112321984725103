import ContactUs from '../components/contactform';
import FILTERS from '../components/filters';
import YourComponent from '../components/ionizers';
import RoSystem from '../components/rosystem';
import ImageGallery from '../components/slider';
import SoftnersSection from '../components/softner';
import '../css/home.css';

function Home() {
  return (
    <div className="container">
      <ImageGallery />
      <SoftnersSection/>
      <FILTERS/>
      <RoSystem/>
      {/* <WhyBuy />
      <HowItWorks/> */}
      {/* <WaterPurifiers /> */}
      <YourComponent/>
      <ContactUs/>
    </div>
  );
}

export default Home;
