import React from 'react';
import '../css/ionizers.css';
import ionizers1 from './../images/ionizers1.png';
import ionizers2 from './../images/ionizers2.png';
import ASTRA from './../images/website/products/astra.png';
import WATER from './../images/website/products/water-dispenser.png';

const YourComponent = () => {
  return (
    <div className="containerio">
      <div className="header1">
        <div className="text-container">
          <h3 className="title">IONIZERS</h3>
          <h2 className="subtitle">
  ASTRA <span className="arrow">→</span>
</h2>
        </div>
        <img src={ASTRA} alt="ASTRA" className="astra" />
      </div>

      <div className="image-overlap">
        <img src={ionizers2} alt="Ionizers 1" className="ionizer-image" />
        <img src={ionizers1} alt="Ionizers 2" className="ionizer-image overlap" />
      </div>

      <div className="dispenser-section">
          <img src={WATER} alt="water" className="water" />
          <div className="text-container1">

          <h3 className="title">DISPENSERS</h3>
          <h2 className="subtitle"> <span className="arrow">←</span>AQUILA</h2>
        </div>
      </div>
    </div>
  );
};

export default YourComponent;
