import '@fortawesome/fontawesome-free/css/all.min.css';
import React, { useState } from 'react';
import '../css/header.css';
import logo from './../images/logo-square.png'; // Import the logo image
import Banner from './banner';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {/* Header Section */}
      <header className="header">
        <div className="card-header">
          <div className="logo-name">
            <img src={logo} alt="Logo" className="logo" />
          </div>

          <button className="dashboard-btn" onClick={toggleMenu}>
            <i className="fas fa-bars"></i>
          </button>

          <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <a href="#home">Home</a>
            <a href="#explore">Explore <span className="down-arrow">▼</span></a>
            <a href="#about">About Us <span className="down-arrow">▼</span></a>
            <button className="book-now">Contact Us</button>
          </nav>
        </div>
      </header>

      {/* Banner Section - Display banner right below the header */}
      <Banner />
    </>
  );
};

export default Header;