import React from 'react';
import '../css/banner.css';
import groupImage from './../images/website/banner/product-linup.png';
import headerImage from './../images/website/banner/background.png';
import headerImageWith from './../images/website/banner/wave-white.webp';


const Banner = () => {
  return (
    <div className="banner-container">
      <img src={headerImage} alt="Header" className="header-image" />
      <img src={groupImage} alt="Group 47" className="group-image" />
      <img src={headerImageWith} alt="Header With" className="header-image-with" />
    </div>
  );
};

export default Banner;
