import React, { useEffect, useRef, useState } from 'react';
import '../css/slider.css';
import irisImage4 from './../images/website/product-line-up/Dispensers.png';
import irisImage2 from './../images/website/product-line-up/Filters.png';
import irisImage3 from './../images/website/product-line-up/Ionizers.png';
import irisImage from './../images/website/product-line-up/ROUTC.png';
import irisImage1 from './../images/website/product-line-up/Softners.png';

const ImageGallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const imagesData = [
    { src: irisImage, description: 'RO UTC' },
    { src: irisImage1, description: 'SOFTNERS' },
    { src: irisImage2, description: 'FILTERS' },
    { src: irisImage3, description: 'IONISERS' },
    { src: irisImage4, description: 'DISPENSERS' },
  ];

  const maxImagesPerScreen = 4; // Maximum images to show at a time
  const autoScrollInterval = 3000; // Auto scroll every 3 seconds
  const timerRef = useRef(null); // Ref for the timer

  // Handle auto scroll using useEffect
  useEffect(() => {
    timerRef.current = setInterval(() => {
      handleRightClick();
    }, autoScrollInterval);

    return () => clearInterval(timerRef.current);
  }); 

  // Handle left and right button click
  const handleLeftClick = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex - maxImagesPerScreen + imagesData.length) % imagesData.length
    );
  };

  const handleRightClick = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + maxImagesPerScreen) % imagesData.length
    );
  };

  // Calculate the images to be displayed
  const displayedImages = [];
  for (let i = 0; i < maxImagesPerScreen; i++) {
    const index = (currentIndex + i) % imagesData.length;
    displayedImages.push(imagesData[index]);
  }

  return (
    <>
      <div className="image-gallery">
        <div className="gallery-heading">
          <h2 className="product-headingslider">OUR PRODUCTS</h2>
          {/* <FaTint className="water-drop-icon" /> */}
        </div>
        <div className="carousel-container">
          <button className="arrow-button left-arrow" onClick={handleLeftClick}>
            &#10094;
          </button>

          <div className="small-images-container">
            {displayedImages.map((imageData, index) => (
              <div key={index} className="image-item">
                <img
                  src={imageData.src}
                  alt={imageData.description}
                  className="imagesliderproduct"
                  // style={{
                  //   width: '250px',
                  //   height: '250px',
                  //   borderRadius: '50%',
                  //   objectFit: 'contain',
                  // }}
                />
                <p>{imageData.description}</p>
              </div>
            ))}
          </div>

          <button className="arrow-button right-arrow" onClick={handleRightClick}>
            &#10095;
          </button>
        </div>
      </div>
      <hr style={{ margin: '30px auto', border: '2px solid #000', width: '95%' }} />
    </>
  );
};

export default ImageGallery;
