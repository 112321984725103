import React from 'react';
import '../css/filters.css';
import headerImageWith from './../images/website/banner/wave-white.webp';
import softnerl from './../images/website/products/dishwasher-filter.webp';
import softners from './../images/website/products/shower-filter.webp';

const FILTERS = () => {
  return (
    <div className="product-section">
      <div className="left-right-container">
        <div className="left-image-container">
          <img src={softners} alt="Shower Filters" className="product-image" />
          <p className="pcolor">SHOWER FILTERS</p>
        </div>
        <div className="right-image-container">
          <img src={softnerl} alt="Dishwasher Filters" className="product-image" />
          <p className="pcolor">DISHWASHER FILTER</p>
        </div>
      </div>

      <div className="footer-overlap">
        <img src={headerImageWith} alt="Wave Footer" className="second-overlap-image" />
      </div>

      <div className="footer-overlap">
  <img
    src={headerImageWith}
    alt="Wave Footer"
    className="footer-image purple-filter"
  />
</div>

    </div>
  );
};

export default FILTERS;
