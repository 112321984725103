import React, { useState } from 'react';
import '../css/purifiers.css';
import irisImage2 from './../images/iris2.png';
import irisImage3 from './../images/MBB-removebg-preview.png';
import irisImage4 from './../images/MWB-removebg-preview.png';
import irisImage from './../images/Product-1.png';
import irisImage1 from './../images/Product-2.png';
import irisImage5 from './../images/water-dispenser.png';

const waterPurifiers = [
  {
    name: "Euro Aqua Delight NXT RO+UV+UF Aquasaver Water Purifier",
    type: "RO+UV+UF",
    feature: "Mineral Aquasaver",
    price: "₹12,499",
    mrp: "MRP ₹22,000 (43% OFF)",
    emi: "No-cost EMI from ₹2,084/mo",
    image: irisImage, // Add your image path here
  },
  {
    name: "Euro Aqua Marvel NXT RO+UV+UF Copper Water Purifier",
    type: "RO+UV+UF",
    feature: "Active Copper",
    price: "₹13,699",
    mrp: "MRP ₹22,000 (37% OFF)",
    emi: "No-cost EMI from ₹2,284/mo",
    image: irisImage1,
  },
  {
    name: "Euro Aqua Aura 2X Life RO Copper Water Purifier",
    type: "RO",
    feature: "Active Copper",
    price: "₹15,999",
    mrp: "MRP ₹29,000 (44% OFF)",
    emi: "No-cost EMI from ₹2,667/mo",
    image: irisImage2,
  },
  {
    name: "Euro Aqua Ritz RO+UV Taste Adjuster Stainless Steel Copper Water Purifier",
    type: "RO+UV",
    feature: "Active Copper, Stainless Steel",
    price: "₹17,199",
    mrp: "MRP ₹27,000 (36% OFF)",
    emi: "No-cost EMI from ₹2,867/mo",
    image: irisImage3,
  },
  {
    name: "Euro Aqua Delight NXT RO+UV+UF Aquasaver Water Purifier",
    type: "RO+UV+UF",
    feature: "Mineral Aquasaver",
    price: "₹12,499",
    mrp: "MRP ₹22,000 (43% OFF)",
    emi: "No-cost EMI from ₹2,084/mo",
    image: irisImage4, // Add your image path here
  },
  {
    name: "Euro Aqua Marvel NXT RO+UV+UF Copper Water Purifier",
    type: "RO+UV+UF",
    feature: "Active Copper",
    price: "₹13,699",
    mrp: "MRP ₹22,000 (37% OFF)",
    emi: "No-cost EMI from ₹2,284/mo",
    image: irisImage5,
  },
  {
    name: "Euro Aqua Aura 2X Life RO Copper Water Purifier",
    type: "RO",
    feature: "Active Copper",
    price: "₹15,999",
    mrp: "MRP ₹29,000 (44% OFF)",
    emi: "No-cost EMI from ₹2,667/mo",
    image: irisImage1,
  },
  {
    name: "Euro Aqua Ritz RO+UV Taste Adjuster Stainless Steel Copper Water Purifier",
    type: "RO+UV",
    feature: "Active Copper, Stainless Steel",
    price: "₹17,199",
    mrp: "MRP ₹27,000 (36% OFF)",
    emi: "No-cost EMI from ₹2,867/mo",
    image: irisImage2,
  },
  // Add other products...
];
const WaterPurifiers = () => {
    const [visibleCount, setVisibleCount] = useState(4); // Start with 12 visible
    const [showLoadMore, setShowLoadMore] = useState(true); // Show Load More button
    const [showAll, setShowAll] = useState(false); // Track if all products are visible
  
    const handleLoadMore = () => {
      setVisibleCount(prevCount => prevCount + 4); // Increase by 12
    };
  
    const handleHideAll = () => {
      setVisibleCount(4); // Reset to show only 12
      setShowLoadMore(true); // Show Load More button again
      setShowAll(false); // Reset to not showing all
    };
  
    return (
      <div className="water-purifiers-container">
        <h2 className="trending-heading">Trending Water Purifiers</h2>
        <div className="cards-container2">
          {waterPurifiers.slice(0, showAll ? waterPurifiers.length : visibleCount).map((purifier, index) => (
            <div key={index} className="card2">
<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' ,width: '60%', height: '60%' }}>
  <img src={purifier.image} alt={purifier.name} className="card-image" style={{ width: '90%', height: '80%' ,marginLeft:'90px'}}/>
</div>
              <h3>{purifier.name}</h3>
              <p>{purifier.type}</p>
              <p>{purifier.feature}</p>
              <p className="price">{purifier.price}</p>
              <p className="mrp">{purifier.mrp}</p>
              <p className="emi">{purifier.emi}</p>
            </div>
          ))}
        </div>
        <div className="view-more-container" style={{ margin: '20px 0', textAlign: 'center' }}>
          {showLoadMore && (
            <button className="view-more-button" onClick={handleLoadMore}>
              View More
            </button>
          )}
          <button className="view-more-button" onClick={handleHideAll}>
            Hide All
          </button>
        </div>
      </div>
    );
  };
  
  export default WaterPurifiers;