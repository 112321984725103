import React from 'react';
import '../css/whybuy.css';

const WhyBuy = () => {
  return (
    <>
      <div className="why-buy-section">
        <h2 className="why-buy-title">Why Buy Euro Aqua Water Purifiers from www.euroaquasystem.com?</h2>
        <div className="cards-container1">
          
          {/* Card 1 */}
          <a href="/exclusive-deals" className="card1">
            <div className="icon blue-icon">%</div>
            <h3>Returning Customer?</h3>
            <p>Get exclusive deals & offers up to ₹3,000 off.</p>
          </a>

          {/* Card 2 */}
          <a href="/warranty" className="card1">
            <div className="icon warranty-icon">🛠️</div>
            <h3>1 Year Warranty</h3>
            <p>1 yr warranty, 2 maintenance visits, service complaints, worth ₹2,000.</p>
          </a>

          {/* Card 3 */}
          <a href="/exchange-purifier" className="card1">
            <div className="icon exchange-icon">🔄</div>
            <h3>Exchange Old Purifier</h3>
            <p>Save up to ₹5,000 by returning an old water purifier.</p>
          </a>

          {/* Card 4 */}
          <a href="/no-cost-emis" className="card1">
            <div className="icon emi-icon">💳</div>
            <h3>No Cost EMIs</h3>
            <p>EMIs for 3 & 6 months on purchases above ₹7,000.</p>
          </a>
        </div>
      </div>

      <hr style={{ margin: '50px auto', border: '2px solid #ccc', width: '95%' }} />
    </>
  );
};

export default WhyBuy;

