import React, { useState } from 'react';
import '../css/contactform.css';

const ContactUs = () => {
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log({ fullName, mobileNumber, message });
  };

  return (
    <div className="contact-us-container">
      <h3 className="contact-us-title">WRITE TO US</h3>
      <form onSubmit={handleSubmit} className="contact-us-form">
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '810px', margin: '20px 0' }}>
          <input
            type="text"
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="contact-us-input1"
            required
          />
          <input
            type="tel"
            placeholder="Mobile Number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="contact-us-input2"
            required
          />
        </div>
        
        <textarea
          placeholder="Your Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="contact-us-textarea"
          required
        />
        <button type="submit" className="contact-us-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
